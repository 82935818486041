<template>
    <div class='tinymce'>
      <editor  id='tinymce' v-model='tinymceHtml' :init='init'></editor>
    </div>
  </template>
  
  <script>
  import tinymce from 'tinymce/tinymce'
  import Editor from '@tinymce/tinymce-vue'
  import 'tinymce/themes/silver/theme'
  import 'tinymce/icons/default/icons'
  import 'tinymce/plugins/image'
  import 'tinymce/plugins/link'
  import 'tinymce/plugins/code'
  import 'tinymce/plugins/table'
  import 'tinymce/plugins/lists'
  import 'tinymce/plugins/contextmenu'
  import 'tinymce/plugins/wordcount'
  import 'tinymce/plugins/colorpicker'
  import 'tinymce/plugins/textcolor'
  export default {
    name: 'tinymce',
    props: {
      //传入一个value，使组件支持v-model绑定
      value: {
        type: String,
        default: ''
      },
    },
    data () {
      return {
        tinymceHtml: this.value,
        init: {
          language_url: './tinymce/langs/zh_CN.js',
          language: 'zh_CN',
          skin_url: './tinymce/skins/ui/oxide',
          height: 400,
          plugins: 'link lists image code table colorpicker textcolor wordcount contextmenu',
          toolbar:
            'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link unlink image code | undo redo | removeformat',
          branding: false,
          setup: function (editor) {
            // 注册一个自定义的按钮
            editor.ui.registry.addButton("imgList", {
              icon: "emoji",
              onAction: function (_) {
                that.showImg = true;
                that.editor = editor;
              },
            });
          },
          paste_data_images: true,  //  设置为允许粘帖图片
          images_upload_url: '/api/files/yctemplate/image/upload', //  图片上传地址
        }
      }
    },
    watch: {
      tinymceHtml(newValue) {
        this.$emit('input', newValue)
      },
    },
    methods:{
      clear(){
        this.tinymceHtml = ''
      }
    },
    mounted () {
      tinymce.init({})
    },
    components: {Editor}
  }
  </script>